<template>
  <div class="add__lgota__wrapper">
    <h3>
      {{ selected_lgota ? "Редактировать льготника" : "Добавить льготника" }}
      <i v-if="selected_lgota" @click="selected_lgota = null" class="fa fa-times" aria-hidden="true"></i>
    </h3>

    <form @submit.prevent="func_add_lgota" v-if="!selected_lgota">
      <div class="form__group">
        <label>Номер лицевого счета или ФИО ученика</label>
        <SearchSelectDb @selected_student="func_selected_student" :teacher="true" />
      </div>
      <div class="form__group">
        <label for="">Тип льготы</label>
        <v-select label="value" :options="type_lgota_list" v-model="new_lgota.type_lgota"
          @option:selecting="func_select_type_lgota" />
      </div>
      <div class="form__group">
        <label>Дата начала</label>
        <input type="date" required v-model="new_lgota.date_start" />
      </div>
      <div class="form__group">
        <label>Дата завершения</label>
        <input type="date" required v-model="new_lgota.date_end" />
      </div>
      <button>Добавить</button>
    </form>

    <form @submit.prevent="func_add_lgota" v-else>
      <div class="form__group">
        <label>Номер лицевого счета </label>
        <input type="text" class="num_ls" readonly :value="selected_lgota.num_ls" />
      </div>
      <div class="form__group">
        <label>Дата начала</label>
        <input type="date" required v-model="selected_lgota.date_start" />
      </div>
      <div class="form__group">
        <label>Дата завершения</label>
        <input type="date" required v-model="selected_lgota.date_end" />
      </div>
      <button>Обновить</button>
    </form>

    <div class="lgota__list">
      <h3>Льгота</h3>
      <!-- <div class="search__block">
        <input type="text" v-model="search_computed" placeholder="Найти" />
        <i class="fa fa-search" aria-hidden="true" @click="func_get_list"></i>
      </div> -->
      <table>
        <thead>
          <tr>
            <th>№ лицевого счета</th>
            <th>ФИО</th>
            <!--<th>Школа</th>
            <th>Класс</th> -->
            <th>Дата начала</th>
            <th>Дата завершения</th>
            <th>Пользователь</th>
            <th>Тип льготы</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in lgota_list" :key="item.id">
            <td>{{ item.num_ls }}</td>
            <td>{{ item.fio }}</td>
            <!--<td>{{ item.num_school }}</td>
            <td>{{ item.num_class }}</td> -->
            <td>{{ new Date(item.date_start).toLocaleDateString() }}</td>
            <td>{{ new Date(item.date_end).toLocaleDateString() }}</td>
            <td>{{ item.login }}</td>
            <td>{{ (item.type_lgota || "").toUpperCase() }}</td>
            <td>
              <button @click="selected_lgota = item" v-if="func_check_user(item.login) && false">
                <i class="fa fa-pencil" aria-hidden="true"></i>
              </button>
            </td>
            <td>
              <button class="trash" @click="remove_lgota(item)" v-if="func_check_user(item.login)">
                <i class="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SearchSelectDb from "@/components/search_select_db.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SearchSelectDb,
  },
  data() {
    return {
      new_lgota: {
        student_id: null,
        date_start: "",
        date_end: "",
        type_lgota: null,
      },
      search: "",
      lgota_list: [],
      type_lgota_list: [],
      selected_lgota: null,
    };
  },
  methods: {
    func_select_type_lgota() { },
    func_get_type_lgota() {
      window.sendRequestAuth("/get_type_lgota_list", {}).then((result) => {
        if (!result) return;
        this.type_lgota_list = result;
      });
    },
    func_selected_student(item) {
      this.new_lgota.student_id = item;
      if (item) this.func_get_list();
      else this.lgota_list = [];
    },
    func_check_user(login) {
      let user_main = this.USER_STATE;
      if (user_main) {
        if (+user_main.type === 5) return true;
        if (user_main.login === login) return true;
      }
      return false;
    },
    func_get_list() {
      window
        .sendRequestAuth("/get_lgota_list", {
          search: this.search,
          student_id: this.new_lgota.student_id || 0,
        })
        .then((result) => {
          if (!result) return;
          this.lgota_list = result;
        });
    },
    func_chek_date(param) {
      let { date_start, date_end } = param;
      if (new Date(date_start) > new Date(date_end)) {
        return false;
      }
      // if (new Date(date_end) < new Date()) {
      //   return false;
      // }
      if (new Date(date_start) < new Date("2000-01-01")) {
        return false;
      }
      if (new Date(date_end) < new Date("2000-01-01")) {
        return false;
      }
      if (
        new Date(date_start) > new Date("2050-01-01") ||
        new Date(date_end) > new Date("2050-01-01")
      ) {
        return false;
      }
      return true;
    },
    func_add_lgota() {
      let param = null;
      if (this.selected_lgota) {
        param = this.selected_lgota;
      } else {
        param = {
          ...this.new_lgota,
          type_lgota_id: this.new_lgota.type_lgota ? this.new_lgota.type_lgota.id : 0,
        };
      }
      if (!this.func_chek_date(param)) {
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Не верные даты",
          type: "error",
        });
        return;
      }
      if (
        !this.new_lgota.student_id ||
        !this.new_lgota.type_lgota ||
        !this.new_lgota.date_start ||
        !this.new_lgota.date_end
      ) {
        this.$notify({
          group: "foo",
          title: "Ошибка",
          text: "Заполните все поля",
          type: "error",
        });
        return;
      }
      window.sendRequestAuth("/add_lgota", param).then((result) => {
        if (!result) return;
        if (result.success) {
          if (this.search.length === 0) {
            this.lgota_list.unshift(result.data);
          }
          this.$notify({
            group: "foo",
            title: "Информация",
            text: result.msg,
            type: "info",
          });
          this.new_lgota.student_id = null;
          document.querySelector(".select__student__db").querySelector("i").click();
          this.new_lgota.type_lgota = null;
          this.new_lgota.date_start = "";
          this.new_lgota.date_end = "";
        } else {
          this.$notify({
            group: "foo",
            title: "Ошибка",
            text: result.msg,
            type: "error",
          });
        }
        window.soundClick();
      });
    },
    remove_lgota(item) {
      if (!item) return;
      if (confirm("Вы действительно хотите удалить льготника?")) {
        window
          .sendRequestAuth("/remove_lgota", {
            id: item.lgota_id,
          })
          .then(() => {
            this.lgota_list.splice(
              this.lgota_list.findIndex((x) => x.lgota_id === item.lgota_id),
              1
            );
            this.$notify({
              group: "foo",
              title: "Информация",
              text: "Запись удалена",
              type: "info",
            });
            window.soundClick();
          });
      }
    },
  },
  mounted() {
    this.func_get_type_lgota();
    //this.func_get_list();
  },
  computed: {
    ...mapGetters(["USER_STATE"]),
    search_computed: {
      get() {
        return this.search;
      },
      set(val) {
        this.search = val;
        this.func_get_list();
      },
    },
  },
};
</script>

<style></style>
